import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AddDeffenceVender1 = () => {
  const [categoryName, setCategoryName] = useState("");
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [venderImage, setVenderImage] = useState(""); // For image URL or uploaded image filename
  const [imageFile, setImageFile] = useState(null); // For file upload
  const [address, setAddress] = useState("");
  const [isPaid, setIsPaid] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const navigate = useNavigate();

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    setImageFile(file);

    const formData = new FormData();
    formData.append("file", file);

    try {
      // Assuming your server has an endpoint to handle image uploads
      const response = await axios.post("http://localhost:3004/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setVenderImage(response.data.filePath); // Assuming the API returns the uploaded file path
      console.log("Image uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newUser = {
      categoryName,
      username,
      phone,
      email,
      venderImage, // Save the image URL or filename
      address,
      isPaid,
      title,
      description,
    };

    axios
      .post("http://localhost:3004/users", newUser)
      .then((response) => {
        console.log("User added successfully:", response.data);
        navigate("/usermanagment/users");
      })
      .catch((error) => {
        console.error("Error adding user:", error);
      });
  };

  return (
    <main className="content px-3 py-2">
      <div className="container-fluid">
        <div className="title-bar">
          <h4>Add New User</h4>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            {/* Left Column */}
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="categoryName" className="form-label">
                  Category Name
                </label>
                <select
                  className="form-control"
                  id="categoryName"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select a category
                  </option>
                  <option value="Squash">Squash</option>
                  <option value="Ping Pong">Ping Pong</option>
                  <option value="Basketball">Basketball</option>
                  <option value="Tennis">Tennis</option>
                  <option value="Football">Football</option>
                  <option value="Carrom">Carrom</option>
                  <option value="Volleyball">Volleyball</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="username" className="form-label">
                  Username
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="venderImage" className="form-label">
                  Upload Vendor Image
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="venderImage"
                  onChange={handleImageUpload}
                  accept="image/*"
                />
              </div>
              {venderImage && (
                <div className="mt-2">
                  <img
                    src={venderImage}
                    alt="Uploaded"
                    style={{ width: "100%", maxHeight: "200px", objectFit: "cover" }}
                  />
                </div>
              )}
            </div>

            {/* Right Column */}
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="address" className="form-label">
                  Address
                </label>
                <textarea
                  className="form-control"
                  id="address"
                  rows="3"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="isPaid" className="form-label">
                  Paid or Free
                </label>
                <select
                  className="form-control"
                  id="isPaid"
                  value={isPaid}
                  onChange={(e) => setIsPaid(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="Paid">Paid</option>
                  <option value="Free">Free</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Description
                </label>
                <textarea
                  className="form-control"
                  id="description"
                  rows="3"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="btn btn-success">
                Add User
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  );
};

export default AddDeffenceVender1;
