import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from '../Sidebar';
import Header from '../Header';
import Footer from '../Footer';
import axios from 'axios';

const AddSportShop1 = () => {

    const [catTitle, setCatTitle] = useState('');

    const [catProfileImage, setCatProfileImage] = useState('');

    const navigate = useNavigate();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer your_token_here',
    };
    const handleSubmit = (e) => {
        e.preventDefault();


        axios.get('http://localhost:3006/shopusers')
            .then((response) => {
                const shopusers = response.data;

                const maxId = shopusers.length > 0 ? Math.max(...shopusers.map(user => parseInt(user.id, 10))) : 0;
                const newUserId = (maxId + 1).toString();


                return axios.post('http://localhost:3006/shopusers', {
                    id: newUserId,
                    catTitle: catTitle,
                   
                    catProfileImage: catProfileImage
                }, {
                    headers: headers
                });
            })
            .then((res) => {
                console.log(res.data);
                navigate("/dashboard/shopusers");
            })
            .catch((err) => {
                console.log(err);
            });
    };




    return (

        <main className="content px-3 py-2">
            <div className="container-fluid">
                <div className="title-bar">
                    <h4>Add New User</h4>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <form >
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Category Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={catTitle}
                                    onChange={(e) => setCatTitle(e.target.value)}
                                    required
                                />
                            </div>

                            
                            <div className="mb-3">
                                <label htmlFor="profileImage" className="form-label">Profile Image URL</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="profileImage"
                                    value={catProfileImage}
                                    onChange={(e) => setCatProfileImage(e.target.value)}
                                />
                            </div>
                            <button type="submit" className="btn btn-success" onClick={handleSubmit}>Add User</button>
                        </form>
                    </div>
                </div>
            </div>
        </main>

    );
};

export default AddSportShop1;
