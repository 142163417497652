import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import khiladiindia from '../assets/khiladilogo.png'
import comingSoonGif from '../assets/coming-soon.gif'
import instaIcon from '../assets/instaIcon.png'
import whatsappIcon from '../assets/whatsappIcon.png'
import callIcon from '../assets/callIcon.png'
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function VendorCard({ data }) {
    const [isHovered, setIsHovered] = useState(false);
    const [isCallHovered, setIsCallHovered] = useState(false);  // State for call button hover
    const [isWhatsAppHovered, setIsWhatsAppHovered] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();


    const handleNavigation = ({sportId,id}) => {
        if (id > 2) {
            setShowModal(true); // Show modal if id is greater than 2
        } else {
            navigate(`/cat/${sportId}/${id}`); // Navigate to the vendor page if id is 2 or less
        }
    };
    const handleGetDirections = () => {
        // Redirect to the Google Maps link
        window.location.href = "https://maps.app.goo.gl/LJ8ePFVC4Pbk71mF6";
    };

    const handleClose = () => setShowModal(false);

    return (
        <>
            {/* Vendor card */}
            <div className="container" >
               
                <div className="row">
                    <div className='col-md-9'
                        style={{
                            ...styles.cardContainer,
                            ...(isHovered ? styles.cardContainerHover : {}), // Apply hover styles
                        }}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        <div style={styles.cardContent}>
                            <div style={styles.imageBox}>
                                <img src={khiladiindia} alt={data.title} style={styles.businessImage} />
                            </div>
                            <div style={styles.infoBox}>
                                <div style={styles.header}>
                                    <h2 style={styles.businessName}>
                                        <span style={styles.icon}></span>
                                        {data.title}
                                    </h2>
                                    <div style={styles.ratings}>
                                        <Button style={styles.getDirection} variant="primary" className="mb-3" onClick={handleGetDirections}>
                                            Get Directions
                                        </Button>
                                    </div>
                                </div>
                                <div style={styles.location}>
                                    <span>📍 {data.address}</span>
                                </div>
                                <div style={styles.tags}>
                                    {data.tags.map((tag, index) => (
                                        <span key={index} style={styles.tag}>{tag}</span>
                                    ))}
                                </div>
                                <div style={styles.cta}>

                                    <a href={`tel:${data.phone}`} style={{
                                        ...styles.callButton,
                                        ...(isCallHovered ? styles.callButtonHover : {}),
                                    }}
                                        onMouseEnter={() => setIsCallHovered(true)}
                                        onMouseLeave={() => setIsCallHovered(false)}
                                    >
                                         <div className="VenderSocial">
                                            <img src={callIcon} ></img>
                                        </div>
                                    </a>

                                    <a
                                        href={`https://wa.me/${data.phone}`}
                                        style={{
                                            ...styles.whatsappButton,
                                            ...(isWhatsAppHovered ? styles.whatsappButtonHover : {}),
                                        }}
                                        onMouseEnter={() => setIsWhatsAppHovered(true)}
                                        onMouseLeave={() => setIsWhatsAppHovered(false)}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <div className="VenderSocial">
                                            <img src={whatsappIcon} ></img>
                                        </div>
                                    </a>
                                    {/* <a>
                                    <div className="VenderSocial">
                                        <img src={instaIcon} ></img>
                                    </div>
                                    </a> */}

                                </div>
                                {/* <div style={styles.recentEnquiries}>
                                    <span>📈 {data.recentEnquiries} people recently enquired</span>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div>
                {/* Modal */}
                {showModal && (
                    <div style={styles.emptyVender}>
                        <div style={styles.emptyVenderContent}>
                            <div style={styles.emptyVenderHeader}>
                                <button style={styles.emptyVendercloseButton} onClick={handleClose}>&times;</button>
                            </div>
                            <img src={comingSoonGif} alt="Coming Soon" />
                            <div style={styles.emptyVenderBody}>
                                We are working on exciting opportunities. Stay tuned!
                            </div>
                            <div style={styles.emptyVenderFooter}>
                                <button style={styles.btnSecondary} onClick={handleClose}>
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

const styles = {
    cardContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: '1px solid #2991c8',
        borderRadius: '10px',
        padding: '10px',
        marginBottom: '20px',
        backgroundColor: '#eee',
        transition: 'transform 0.3s, box-shadow 0.3s',
        marginTop: '20px', // Adjust based on your navbar height
    },
    cardContainerHover: {
        transform: 'scale(1.02)',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
    },
    cardContent: {
        display: 'flex',
        width: '100%',
    },
    imageBox: {
        flexBasis: '25%',
        paddingRight: '15px',
    },
    businessImage: {
        width: '100%',
        height: 'auto',
        borderRadius: '5px',
    },
    infoBox: {
        flexBasis: '75%',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    businessName: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    icon: {
        marginRight: '10px',
    },
    ratings: {
        display: 'flex',
        alignItems: 'center',
    },
    getDirectionHover: {
        backgroundColor: '#green !important',
    },

    rating: {
        color: 'green',
        fontWeight: 'bold',
        marginRight: '5px',
    },
    ratingsText: {
        marginLeft: '5px',
        color: '#555',
    },
    badge: {
        backgroundColor: 'green',
        color: 'white',
        padding: '3px 7px',
        borderRadius: '5px',
        marginLeft: '10px',
    },
    location: {
        margin: '10px 0',
        color: '#333',
    },
    tags: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '5px',
    },
    tag: {
        backgroundColor: 'lightpink',
        padding: '5px 10px',
        fontWeight: '500',
        borderRadius: '5px',
    },
    cta: {
        display: 'flex',
        gap: '10px',
        marginTop: '10px',
    },
    
    recentEnquiries: {
        marginTop: '10px',
        fontSize: '12px',
        color: 'gray',
    },
    // New mobile styling for centering
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    emptyVender: {
        display: 'flex',
        position: 'fixed',
        zIndex: 1,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: ' blur(15px)',

    },
    emptyVenderContent: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '5px',
        width: '80%',
        maxWidth: '500px',
        textAlign: 'center',
    },
    emptyVenderHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        float: 'right',
    },
    emptyVenderTitle: {
        fontSize: '1.5rem',
        marginLeft: '30px',
    },
    emptyVendercloseButton: {
        fontSize: '1.5rem',
        cursor: 'pointer',
        border: 'none',
        background: 'none',
    },
    emptyVenderBody: {
        margin: '20px 0',
    },
    emptyVenderFooter: {
        display: 'inline-block',
        justifyContent: 'flex-end',
        paddingTop: '10px',
    },
    btnSecondary: {
        padding: '10px 20px',
        fontSize: '1rem',
        backgroundColor: '#6c757d',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
        borderRadius: '8px',

    },
    btnSecondaryHover: {
        backgroundColor: '#8d9ead',
    },


};


export default VendorCard;
