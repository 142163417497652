import './App.css';
import { Route, Routes } from "react-router-dom";
import HomePage from './pages/HomePage';
import Navbar from './components/Navbar';
import TermAndConditions from './pages/TermAndConditions';
import Footer from './components/Footer';
import VenderListingAcademy from './pages/VenderListingAcademy';
import VenderPage1 from './pages/VenderPage1';
import PopUpMessage from './components/PopUpMessage';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import Userregistor from './modules/dashboard/auth/Userregistor';
import Dashboard from './modules/dashboard/Dashboard';
import Users from "./modules/dashboard/usermanagment/Users"
import UserList from './modules/dashboard/auth/UserList';
import AddUser from './modules/dashboard/usermanagment/AddUser';
import EditUser from './modules/dashboard/usermanagment/EditUser';
import EmptyPage from './components/EmptyPage';
import { useEffect, useState } from 'react';
import ScrollToTop from './components/ScrollToTop';
import DeffenceTrainingCe from './components/DeffenceTrainingCe';
import EditDeffenceVender from './modules/dashboard/deffenceAcademyUser/EditDeffenceVender';
import TotalVenders from './modules/dashboard/deffenceAcademyUser/TotalVenders';
import DashboardHome from './modules/dashboard/DashboardHome';
import AddSportShop from './modules/dashboard/SportsShopManagement/AddSportShop';
import TotalSportShop from './modules/dashboard/SportsShopManagement/TotalSportShop';
import AddDeffenceVender from './modules/dashboard/deffenceAcademyUser/AddDeffenceVender';
import UserTable from './modules/dashboard/usermanagment/Users';
import categories from "./dummyData/acadmic.json"
import EditSportShop from './modules/dashboard/SportsShopManagement/EditSportShop';
import Dashboard1 from './modules/dashboard2/Dashboard';
import TotalVenders1 from './modules/dashboard2/deffenceAcademyUser/TotalVenders';
import TotalSportShop1 from './modules/dashboard2/SportsShopManagement/TotalSportShop';
import AddDeffenceVender1 from './modules/dashboard2/deffenceAcademyUser/AddDeffenceVender';
import AddSportShop1 from './modules/dashboard2/SportsShopManagement/AddSportShop';
import EditDeffenceVender1 from './modules/dashboard2/deffenceAcademyUser/EditDeffenceVender';
import EditSportShop1 from './modules/dashboard2/SportsShopManagement/EditSportShop';
import DashboardHome1 from './modules/dashboard2/DashboardHome';
import EditUser1 from './modules/dashboard2/usermanagment/EditUser';
import AddUser1 from './modules/dashboard2/usermanagment/AddUser';
import UserTable1 from './modules/dashboard2/usermanagment/Users';

function App() {
  useEffect(() => {
    console.log("page load")
    window.scrollTo(0, 0);
  }, []);
  const [isAdmin, setIsAdmin] = useState(false);

  return (
    <>
      <Navbar />
      <PopUpMessage />
      <main style={{ paddingTop: "100px", overflowY: 'hidden' }}>
        <ScrollToTop />
        <Routes>
          <Route path='/KhiladiIndia' element={<HomePage />} />
          <Route index element={<HomePage />} />
          <Route path='/cat/:sportId' element={<VenderListingAcademy />} />
          <Route path='/cat/:sportId/:subcategoryId' element={<VenderPage1 categories={categories} />} />
          <Route path='/termAndConditions' element={<TermAndConditions />} />
          <Route path="/HomePage" element={<HomePage />} />
          <Route path="createaccount" element={<Userregistor />} />
          <Route path="/dashboard" element={<Dashboard />}>
            <Route path='/dashboard' element={<DashboardHome />} />
            <Route path='total-users' element={<Users />} />
            <Route path='total-venders' element={<TotalVenders />} />
            <Route path='total-SportShop' element={<TotalSportShop />} />
            <Route path='add-user' element={<AddUser />} />
            <Route path='add-venders' element={<AddDeffenceVender />} />
            <Route path='add-SportShop' element={<AddSportShop />} />
            <Route path="edit-user/:id" element={<EditUser />} />
            <Route path='edit-vender/:id' element={<EditDeffenceVender />} />
            <Route path='edit-SportShop/:id' element={<EditSportShop />} />
          </Route>
          <Route path="/UserDashboard" element={<Dashboard1 />}>
            <Route path='/UserDashboard' element={<DashboardHome1 />} />
            <Route path='total-users1' element={<UserTable1 />} />
            <Route path='total-venders1' element={<TotalVenders1 />} />
            <Route path='total-SportShop1' element={<TotalSportShop1 />} />
            <Route path='add-user1' element={<AddUser1 />} />
            <Route path='add-venders1' element={<AddDeffenceVender1 />} />
            <Route path='add-SportShop1' element={<AddSportShop1 />} />
            <Route path="edit-user1/:id" element={<EditUser1 />} />
            <Route path='edit-vender1/:id' element={<EditDeffenceVender1 />} />
            <Route path='edit-SportShop1/:id' element={<EditSportShop1 />} />
          </Route>
          <Route path="/sports" element={<EmptyPage />} />
          <Route path="usermanagment/userlist" element={<UserList />} />
          <Route path="/defenceacademy" element={<DeffenceTrainingCe isAdmin={isAdmin} />} />
        </Routes>
      </main>
      <Footer />
    </>

  );
}

export default App;
