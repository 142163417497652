import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import VENDERUSER from '../../../dummyData/acadmic.json'

const EditUser = () => {
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [title, setTitle] = useState("");
  const [timings, setTimings] = useState("");
  const [ratings, setRatings] = useState("");
  const [yearsExperience, setYearsExperience] = useState("");
  const [about, setAbout] = useState("");
  const [catelogue, setCatelogue] = useState("");
  const [heading, setHeading] = useState("");
  const [AskForPrice, setAskForPrice] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [isPaid, setIsPaid] = useState("");
  const [mapLocation, setMapLocation] = useState("");
  const [profileUrl, setProfileUrl] = useState("");

  
  const { id } = useParams(); // Get user ID from the URL
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Fetch user details by ID
    axios
      .get(`http://localhost:3004/categoryanduser/${id}`)
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user:", error);
      });
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Make PUT request to update user data
    axios
      .put(`http://localhost:3004/categoryanduser/${id}`, user)
      .then((response) => {
        console.log("User updated successfully:", response.data);
        navigate("/usermanagment/categoryanduser");
      })
      .catch((error) => {
        console.error("Error updating user:", error);
      });
  };

  // if (!user) {
  //   return <div>Loading...</div>;
  // }

  return (
    <main className="content px-3 py-2">
      <div className="container-fluid">
        <div className="title-bar">
          <h4>Edit User</h4>
        </div>
        <form onSubmit={handleSubmit}>
        <div className="row">
  {/* Left Column */}
  <div className="col-md-4">
    <div className="mb-3">
      <label htmlFor="bannerImage" className="form-label">
        Banner Image URL
      </label>
      <input
        type="text"
        className="form-control"
        id="bannerImage"
        value={bannerImage}
        onChange={(e) => setBannerImage(e.target.value)}
      />
    </div>
    <div className="mb-3">
      <label htmlFor="title" className="form-label">
        Title
      </label>
      <input
        type="text"
        className="form-control"
        id="title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
    </div>
    <div className="mb-3">
      <label htmlFor="isPaid" className="form-label">
        Paid or Free
      </label>
      <select
        className="form-control"
        id="isPaid"
        value={isPaid}
        onChange={(e) => setIsPaid(e.target.value)}
        required
      >
        <option value="" disabled>
          Select
        </option>
        <option value="Paid">Paid</option>
        <option value="Free">Free</option>
      </select>
    </div>
    <div className="mb-3">
      <label htmlFor="phone" className="form-label">
        Phone
      </label>
      <input
        type="text"
        className="form-control"
        id="phone"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        required
      />
    </div>
    <div className="mb-3">
      <label htmlFor="email" className="form-label">
        Email
      </label>
      <input
        type="email"
        className="form-control"
        id="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
    </div>
    <div className="mb-3">
      <label htmlFor="categoryName" className="form-label">
        Category Name
      </label>
      <select
        className="form-control"
        id="categoryName"
        value={categoryName}
        onChange={(e) => setCategoryName(e.target.value)}
        required
      >
        <option value="" disabled>
          Select a category
        </option>
        <option value="Squash">Squash</option>
        <option value="Ping Pong">Ping Pong</option>
        <option value="Basketball">Basketball</option>
        <option value="Tennis">Tennis</option>
        <option value="Football">Football</option>
        <option value="Carrom">Carrom</option>
        <option value="Volleyball">Volleyball</option>
      </select>
    </div>
    <div className="mb-3">
      <label htmlFor="timings" className="form-label">
        Timings
      </label>
      <input
        type="text"
        className="form-control"
        id="timings"
        value={timings}
        onChange={(e) => setTimings(e.target.value)}
      />
    </div>
  </div>

  {/* Middle Column */}
  <div className="col-md-4">
    <div className="mb-3">
      <label htmlFor="username" className="form-label">
        Username
      </label>
      <input
        type="text"
        className="form-control"
        id="username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        required
      />
    </div>
    <div className="mb-3">
      <label htmlFor="password" className="form-label">
        Password
      </label>
      <input
        type="password"
        className="form-control"
        id="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
    </div>
    <div className="mb-3">
      <label htmlFor="confirmPassword" className="form-label">
        Confirm Password
      </label>
      <input
        type="password"
        className="form-control"
        id="confirmPassword"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        required
      />
    </div>
    <div className="mb-3">
      <label htmlFor="ratings" className="form-label">
        Ratings
      </label>
      <input
        type="number"
        className="form-control"
        id="ratings"
        value={ratings}
        onChange={(e) => setRatings(e.target.value)}
      />
    </div>
    <div className="mb-3">
      <label htmlFor="yearsExperience" className="form-label">
        Years of Experience
      </label>
      <input
        type="number"
        className="form-control"
        id="yearsExperience"
        value={yearsExperience}
        onChange={(e) => setYearsExperience(e.target.value)}
      />
    </div>
    <div className="mb-3">
      <label htmlFor="about" className="form-label">
        About
      </label>
      <textarea
        className="form-control"
        id="about"
        rows="3"
        value={about}
        onChange={(e) => setAbout(e.target.value)}
      />
    </div>
    <div className="mb-3">
      <label htmlFor="catelogue" className="form-label">
        Catalogue URL
      </label>
      <input
        type="text"
        className="form-control"
        id="catelogue"
        value={catelogue}
        onChange={(e) => setCatelogue(e.target.value)}
      />
    </div>
  </div>

  {/* Right Column */}
  <div className="col-md-4">
    <div className="mb-3">
      <label htmlFor="heading" className="form-label">
        Heading
      </label>
      <input
        type="text"
        className="form-control"
        id="heading"
        value={heading}
        onChange={(e) => setHeading(e.target.value)}
        required
      />
    </div>
    <div className="mb-3">
      <label htmlFor="AskForPrice" className="form-label">
        Ask For Price
      </label>
      <input
        type="text"
        className="form-control"
        id="AskForPrice"
        value={AskForPrice}
        onChange={(e) => setAskForPrice(e.target.value)}
        required
      />
    </div>
    <div className="mb-3">
      <label htmlFor="address" className="form-label">
        Address
      </label>
      <textarea
        className="form-control"
        id="address"
        rows="3"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        required
      />
    </div>
    <div className="mb-3">
      <label htmlFor="mapLocation" className="form-label">
        Map Location
      </label>
      <input
        type="text"
        className="form-control"
        id="mapLocation"
        value={mapLocation}
        onChange={(e) => setMapLocation(e.target.value)}
      />
    </div>
    <div className="mb-3">
      <label htmlFor="profileUrl" className="form-label">
        Profile Image URL
      </label>
      <input
        type="text"
        className="form-control"
        id="profileUrl"
        value={profileUrl}
        onChange={(e) => setProfileUrl(e.target.value)}
      />
    </div>
    <div className="mb-3">
      <label htmlFor="state" className="form-label">
        State
      </label>
      <input
        type="text"
        className="form-control"
        id="state"
        value={state}
        onChange={(e) => setState(e.target.value)}
        required
      />
    </div>
    <div className="mb-3">
      <label htmlFor="city" className="form-label">
        City
      </label>
      <input
        type="text"
        className="form-control"
        id="city"
        value={city}
        onChange={(e) => setCity(e.target.value)}
        required
      />
    </div>
  </div>

  <button type="submit" className="btn btn-success">
    Update User
  </button>
</div>

        </form>
      </div>
    </main>
  );
};

export default EditUser;
