import React, { useState } from 'react';
import './PopUp.css';
import whatsapp from '../assets/Whatsapp.gif';
import registeralert from '../assets/registerAlert.png';

function App() {
  const [showForm, setShowForm] = useState(false);
  const [paymentType, setPaymentType] = useState('free');
  const [selectedOption, setSelectedOption] = useState('Free'); // Default to "Free"
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    city: '',
    gender: '',
    category: '',
    paymentType: '',
  });

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const handlePaymentTypeChange = (e) => {
    setPaymentType(e.target.value);
  };

  // const handleOptionChange = (option) => {
  //   setSelectedOption(option);
  // };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappMessage = `Full Name: ${formData.name}\nEmail: ${formData.email}\nNumber: ${formData.number}\nCity: ${formData.city}\nGender: ${formData.gender}\nCategory: ${formData.category}\nPayment Option: ${selectedOption}`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=8279848804&text=${encodeURIComponent(whatsappMessage)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="App">
      <div className="registerMe">
        <img src={registeralert} className='form titles registerImg' onClick={toggleForm} style={{color:"#28a745",width:"100px", fontSize:"70px"}}></img>
      </div>
      <img src={whatsapp} className='form titles openFormBtn' onClick={toggleForm} style={{ color: "#28a745", fontSize: "70px" }}></img>
      {showForm && (
        <div className="contact-form-wrapper">
          <form className="contact-form" onSubmit={handleSubmit}>
            <h3 className="form-title">How Can We Help?</h3>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="name">Full Name</label>
                <input
                  type="text"
                  id="name"
                  placeholder="Full Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  id="city"
                  placeholder="Enter City"
                  value={formData.city}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="number">Number</label>
                <input
                  type="tel"
                  id="number"
                  placeholder="Number"
                  value={formData.number}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="gender">Gender</label>
                <select
                  id="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="category">Category</label>
                <select
                  id="category"
                  value={formData.category}
                  onChange={handleInputChange}
                >
                  <option value="">Select Category</option>
                  <option value="HR">HR</option>
                  <option value="Finance">Finance</option>
                  <option value="Engineering">Engineering</option>
                  <option value="Marketing">Marketing</option>
                </select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="paymentType">Payment Option</label>
                <select
                  id="paymentType"
                  value={formData.paymentType}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Payment Type</option>
                  <option value="free">Free</option>
                  <option value="paid">Paid</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="form-footer col-md-8">
                <button type="submit" className="submit-btn">Send Message</button>
              </div>
              <div className="form-footer col-md-4">
                <button type="button" className="close1-btn" onClick={toggleForm}>
                  Close
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default App;
