import React from 'react';

const EmptyPage = () => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      background: 'linear-gradient(135deg, #2991c8, #E86F2E)',
      textAlign: 'center',
      animation: 'fadeIn 1s ease-in-out',

    },
    title: {
      fontSize: '3rem',
      fontWeight: 'bold',
      color: '#fff',
      marginBottom: '20px',
    },
    message: {
      fontSize: '1.5rem',
      color: '#eee',
      position: 'relative',
      animation: 'bounce 2s infinite',
    },
  
  };

  // Animation keyframes
  const bounceAnimation = `
    @keyframes bounce {
      0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-10px);
      }
      60% {
        transform: translateY(-5px);
      }
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `;

  return (
    <div style={styles.container}>
      <style>{bounceAnimation}</style>
        <h2 style={styles.title}>Coming Soon</h2>
        <p style={styles.message}>Connect With Us...</p>
      </div>
  );
};

export default EmptyPage;
