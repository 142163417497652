import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AddUser = () => {
  // State variables for form data
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [title, setTitle] = useState("");
  const [timings, setTimings] = useState("");
  const [ratings, setRatings] = useState("");
  const [yearsExperience, setYearsExperience] = useState("");
  const [about, setAbout] = useState("");
  const [catelogue, setCatelogue] = useState("");
  const [heading, setHeading] = useState("");
  const [askForPrice, setAskForPrice] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [isPaid, setIsPiad] = useState("");
  const [mapLocation, setMapLocation] = useState("");
  const [profileUrl, setProfileUrl] = useState("");

//cloudinary
  // const [images, setImages] = useState([null, null, null, null, null, null, null, null, null, null, null, null, null, null]); // Store images for all 14 inputs
  // const [imageURLs, setImageURLs] = useState([]);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a new user object
    const newUser = {
      bannerImage,
      username,
      phone,
      email,
      profileUrl,
      address,
      isPaid,
      title,
      mapLocation,
      about,
      askForPrice,
      heading,
      catelogue,
      yearsExperience,
      ratings,
      timings,
      password,
      confirmPassword,
      city,
      state,
    };

    // Make POST request to save the data in JSON
    axios
      .post("http://localhost:3004/categoryanduser", newUser)
      .then((response) => {
        console.log("User added successfully:", response.data);
        navigate("/usermanagment/categoryanduser");
      })
      .catch((error) => {
        console.error("Error adding user:", error);
      });
  };


  // Handle image change for each input field
  // const handleImageChange = (e, index) => {
  //   const updatedImages = [...images];
  //   updatedImages[index] = e.target.files[0]; // Update the image at the specific index
  //   setImages(updatedImages);
  // };

  // // Handle the upload of multiple images
  // const handleUpload = async () => {
  //   // Filter out null values (images that weren't selected)
  //   const selectedImages = images.filter((image) => image !== null);

  //   if (selectedImages.length === 0) {
  //     alert("Please select at least one image!");
  //     return;
  //   }

  //   const uploadPromises = selectedImages.map((image) => {
  //     const formData = new FormData();
  //     formData.append("file", image);
  //     formData.append("upload_preset", "Montythakur98"); // Replace with your unsigned preset name

  //     return axios
  //       .post(`https://api.cloudinary.com/v1_1/dey3w8kei/image/upload`, formData) // Replace with your actual Cloudinary cloud name
  //       .then((response) => response.data.secure_url)  // Return the uploaded image URL
  //       .catch((error) => {
  //         console.error("Error uploading image:", error);
  //         alert("Failed to upload image. Please try again.");
  //       });
  //   });

  //   // Wait for all uploads to finish
  //   try {
  //     const uploadedURLs = await Promise.all(uploadPromises);
  //     setImageURLs(uploadedURLs);  // Save the uploaded URLs
  //     alert("Images uploaded successfully!");
  //   } catch (error) {
  //     console.error("Error uploading images:", error);
  //   }
  // };


  return (
    <main className="content px-3 py-2">
      <div className="container-fluid">
          <div className="title-bar">
            <h4>Add New User</h4>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="row">
              {/* Left Column */}
              <div className="col-md-4">
                <div className="mb-3">
                  <label htmlFor="bannerImage" className="form-label">
                    Upload Banner Image
                  </label>
                  <input
                    type="file" 
                    onChange={(e) => setBannerImage(e.target.value)}
                    className="form-control"
                    id="bannerImage"
                    
                  />

                </div>
                <div className="mb-3">
                  <label htmlFor="profileImage" className="form-label">
                    Upload Profile Image
                  </label>
                  <input
                    type="file" 
                    onChange={(e) => setProfileUrl(e.target.value)}
                    className="form-control"
                    id="profileImage"
                    
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="title" className="form-label">
                    Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="isPaid" className="form-label">
                    Paid or Free
                  </label>
                  <select
                    className="form-control"
                    id="isPaid"
                    value={isPaid}
                    onChange={(e) => setIsPiad(e.target.value)}
                    
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="Paid">Paid</option>
                    <option value="Free">Free</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">
                    Phone
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="categoryName" className="form-label">
                    Category Name
                  </label>
                  <select
                    className="form-control"
                    id="categoryName"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                    
                  >
                    <option value="" disabled>
                      Select a category
                    </option>
                    <option value="Squash">Squash</option>
                    <option value="Ping Pong">Ping Pong</option>
                    <option value="Basketball">Basketball</option>
                    <option value="Tennis">Tennis</option>
                    <option value="Football">Football</option>
                    <option value="Carrom">Carrom</option>
                    <option value="Volleyball">Volleyball</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="timings" className="form-label">
                    Timings
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="timings"
                    value={timings}
                    onChange={(e) => setTimings(e.target.value)}
                    
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="city" className="form-label">
                    Heading
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    value={heading}
                    onChange={(e) => setHeading(e.target.value)}
                    
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="city" className="form-label">
                    Ask For Price
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    value={askForPrice}
                    onChange={(e) => setAskForPrice(e.target.value)}
                    
                  />
                </div>
              </div>

              {/* Middle Column */}

              <div className="col-md-4">
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">
                    Username
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="confirmPassword" className="form-label">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="ratings" className="form-label">
                    Ratings
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="ratings"
                    value={ratings}
                    onChange={(e) => setRatings(e.target.value)}

                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="yearsExperience" className="form-label">
                    Years of Experience
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="yearsExperience"
                    value={yearsExperience}
                    onChange={(e) => setYearsExperience(e.target.value)}
                    
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="about" className="form-label">
                    About
                  </label>
                  <textarea
                    className="form-control"
                    id="about"
                    rows="3"
                    value={about}
                    onChange={(e) => setAbout(e.target.value)}
                    
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="address" className="form-label">
                    Address
                  </label>
                  <textarea
                    className="form-control"
                    id="address"
                    rows="3"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="mapLocation" className="form-label">
                    Map Location
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="mapLocation"
                    value={mapLocation}
                    onChange={(e) => setMapLocation(e.target.value)}
                    
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="state" className="form-label">
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="state"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    
                  />
                </div>
              </div>

              {/* Right Side */}
              <div className="col-md-4">
                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images (1)
                  </label>
                  <input
                    type="file" 
                  onChange={(e) => setCatelogue(e.target.value)}
                                      className="form-control"
                    id="catalogueImages"
                    multiple
                  />
                  {/* <small>{`Selected: ${catalogueImages.length} images`}</small> */}
                </div>
                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images (2)
                  </label>
                  <input
                    type="file"
                  onChange={(e) => setCatelogue(e.target.value)}
                                      className="form-control"
                    id="catalogueImages"
                    multiple
                  />
                  {/* <small>{`Selected: ${catalogueImages.length} images`}</small> */}
                </div>
                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images (3)
                  </label>
                  <input
                    type="file"
                  onChange={(e) => setCatelogue(e.target.value)}
                                      className="form-control"
                    id="catalogueImages"
                    multiple
                  />
                  {/* <small>{`Selected: ${catalogueImages.length} images`}</small> */}
                </div>
                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images (4)
                  </label>
                  <input
                    type="file"
                  onChange={(e) => setCatelogue(e.target.value)}
                                      className="form-control"
                    id="catalogueImages"
                    multiple
                  />
                  {/* <small>{`Selected: ${catalogueImages.length} images`}</small> */}
                </div>
                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images (5)
                  </label>
                  <input
                    type="file"
                  onChange={(e) => setCatelogue(e.target.value)}
                                      className="form-control"
                    id="catalogueImages"
                    multiple
                  />
                  {/* <small>{`Selected: ${catalogueImages.length} images`}</small> */}
                </div>
                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images (6)
                  </label>
                  <input
                    type="file"
                  onChange={(e) => setCatelogue(e.target.value)}
                                      className="form-control"
                    id="catalogueImages"
                    multiple
                  />
                  {/* <small>{`Selected: ${catalogueImages.length} images`}</small> */}
                </div>
                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images (7)
                  </label>
                  <input
                    type="file"
                  onChange={(e) => setCatelogue(e.target.value)}
                                      className="form-control"
                    id="catalogueImages"
                    multiple
                  />
                  {/* <small>{`Selected: ${catalogueImages.length} images`}</small> */}
                </div>
                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images (8)
                  </label>
                  <input
                    type="file"
                  onChange={(e) => setCatelogue(e.target.value)}
                                      className="form-control"
                    id="catalogueImages"
                    multiple
                  />
                  {/* <small>{`Selected: ${catalogueImages.length} images`}</small> */}
                </div>
                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images (9)
                  </label>
                  <input
                    type="file"
                  onChange={(e) => setCatelogue(e.target.value)}

                    className="form-control"
                    id="catalogueImages"
                    multiple
                  />
                  {/* <small>{`Selected: ${catalogueImages.length} images`}</small> */}
                </div>
                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images (10)
                  </label>
                  <input
                    type="file"
                  onChange={(e) => setCatelogue(e.target.value)}

                    className="form-control"
                    id="catalogueImages"
                    multiple
                  />
                  {/* <small>{`Selected: ${catalogueImages.length} images`}</small> */}
                </div>
                <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images (11)
                  </label>
                  <input
                    type="file"
                  onChange={(e) => setCatelogue(e.target.value)}

                    className="form-control"
                    id="catalogueImages"
                    multiple
                  />
                  {/* <small>{`Selected: ${catalogueImages.length} images`}</small> */}
                </div> <div className="mb-3">
                  <label htmlFor="catalogueImages" className="form-label">
                    Upload Catalogue Images (12)
                  </label>
                  <input
                    type="file"
                  onChange={(e) => setCatelogue(e.target.value)}

                    className="form-control"
                    id="catalogueImages"
                    multiple
                  />
                  {/* <small>{`Selected: ${catalogueImages.length} images`}</small> */}
                </div>
              </div>
              {/* onClick={handleUpload} */}
              <button type="submit" className="btn btn-success">
                Add User
              </button>
            </div>
          </form>
        </div>
    </main>
  );
};

export default AddUser;
