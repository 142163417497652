import React, { useState } from "react";
import "./CheckoutForm.css";
import cardImage from "../assets/card_img.png";

const TrainerRegistration = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);

  const handleRegisterClick = () => {
    setIsFormVisible(true);
    document.body.style.overflow = "hidden"; // Prevent background scrolling
  };

  const handleCloseForm = () => {
    setIsFormVisible(false);
    document.body.style.overflow = "auto"; // Restore scrolling
  };

  return (
    <div className="checkout-container">
      {/* Banner Section */}
      <div className={`checkout-banner ${isFormVisible ? "shifted-banner" : ""}`}>
        <div className="checkout-banner-content">
          <h1 className=""
            onClick={handleRegisterClick}>Become a Certified Trainer</h1>
          <p>
            Join our community of skilled trainers. Register now and take the
            first step towards your journey as a professional sports trainer.
          </p>
          <button
            className="checkout-banner-button"
            onClick={handleRegisterClick}
          >
            Register Now
          </button>
        </div>
      </div>

      {/* Checkout Form Popup */}
      {isFormVisible && (
        <>
          <div className="checkout-modal-overlay" onClick={handleCloseForm}></div>
          <div className="checkout-form-wrapper">
            <form className="checkout-form">
              
              <div className="row">
                <div className="col-6 col-md-6">
                <h3 className="checkout-title">Billing Address</h3>
                  <div className="checkout-inputBox">
                    <span>Full Name:</span>
                    <input type="text" placeholder="Enter Full Name " />
                  </div>
                  <div className="checkout-inputBox">
                    <span>Email:</span>
                    <input type="email" placeholder="Enter email Id" />
                  </div>
                  <div className="checkout-inputBox">
                    <span>Address:</span>
                    <input type="text" placeholder="Enter address" />
                  </div>
                  <div className="checkout-inputBox">
                    <span>City:</span>
                    <input type="text" placeholder="Enter City" />
                  </div>
                  <div className="checkout-flex">
                    <div className="checkout-inputBox">
                      <span>State:</span>
                      <input type="text" placeholder="Enter State" />
                    </div>
                    <div className="checkout-inputBox">
                      <span>Zip Code:</span>
                      <input type="text" placeholder="PinCode" />
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-6">
                  <h3 className="checkout-title">Payment</h3>
                  <div className="checkout-inputBox">
                    <span>Cards Accepted:</span>
                    <img className="imgCard" src={cardImage} alt="Accepted Cards" />
                  </div>
                  <div className="checkout-inputBox">
                    <span>Name on Card:</span>
                    <input type="text" placeholder="Mr. Monty Thakur" />
                  </div>
                  <div className="checkout-inputBox">
                    <span>Credit Card Number:</span>
                    <input type="number" placeholder="1111-2222-3333-4444" />
                  </div>
                  <div className="checkout-inputBox">
                    <span>Exp Month:</span>
                    <input type="text" placeholder="January" />
                  </div>
                  <div className="checkout-flex">
                    <div className="checkout-inputBox">
                      <span>Exp Year:</span>
                      <input type="number" placeholder="2022" />
                    </div>
                    <div className="checkout-inputBox">
                      <span>CVV:</span>
                      <input type="text" placeholder="1234" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-actions">
                <input
                  type="submit"
                  value="Proceed to Checkout"
                  className="checkout-submit-btn"
                />
                <button
                  type="button"
                  className="checkout-close-btn"
                  onClick={handleCloseForm}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default TrainerRegistration;
