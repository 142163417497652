import React, { useEffect, useState } from 'react';
import { Link, } from 'react-router-dom';
import Sidebar from '../Sidebar';
import Header from '../Header';
import Footer from '../Footer';
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { IoIosAddCircleOutline } from "react-icons/io";
import Swal from 'sweetalert2';
import p1 from "../../../assets/img_1.jpg";
import p2 from "../../../assets/img_2.jpg";
import p3 from "../../../assets/img_3.jpg";
import p4 from "../../../assets/img_4.jpg";
import p5 from "../../../assets/img_5.jpg";
import DEFENCEDATA from '../../../dummyData/DAdb.json'



import axios from 'axios';

const TotalVenders = () => {
   
    const [users, setusers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

   

    const exercises = [
        { id: 1, image: p1, title: "Biceps Curl", instructor: "Justin Daniel", duration: "30 minutes" },
        { id: 2, image: p2, title: "Triceps Pushdown", instructor: "Justin Daniel", duration: "30 minutes" },
        { id: 3, image: p3, title: "Leg Press", instructor: "Justin Daniel", duration: "30 minutes" },
        { id: 4, image: p4, title: "Chest Press", instructor: "Justin Daniel", duration: "30 minutes" },
        { id: 5, image: p5, title: "Deadlift", instructor: "Justin Daniel", duration: "30 minutes" },
        { id: 6, image: p1, title: "Pull-Up", instructor: "Justin Daniel", duration: "30 minutes" },
        { id: 7, image: p2, title: "Lat Pulldown", instructor: "Justin Daniel", duration: "30 minutes" },
        { id: 8, image: p3, title: "Squat", instructor: "Justin Daniel", duration: "30 minutes" },
        { id: 9, image: p4, title: "Cable Fly", instructor: "Justin Daniel", duration: "30 minutes" },
        { id: 10, image: p5, title: "Crunches", instructor: "Justin Daniel", duration: "30 minutes" }
      ];
   
      useEffect(() => {
        fetchUser();
    }, []);


    const fetchUser = () => {
        axios.get('http://localhost:3004/users')
            .then((response) => {
                console.log(response.data);
                setusers(response.data);
            })
            .catch((error) => {
                console.error('Error fetching users:', error);
            });
    };

    const deleteHandleUser = (id) => {

        // SweetAlert2 confirmation dialog
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        })
            .then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`http://localhost:3004/users/${id}`)
                        .then(() => {

                            fetchUser();
                            Swal.fire(
                                'Deleted!',
                                'User has been deleted.',
                                'success'
                            );
                        })
                        .catch((error) => {
                            console.error('Error deleting user:', error);
                            Swal.fire(
                                'Error!',
                                'There was an error deleting the user.',
                                'error'
                            );
                        });
                }
            });
    };
    const filteredusers = users.filter(user =>
        user.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.instructor.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.duration.includes(searchQuery)
    );
   
    return (
        <main className="content px-3 py-2">
            <div className="container-fluid">
                <div className="title-bar1">
                    <h4>Vender Management</h4>
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item"><a>Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Users List</li>
                    </ol>
                </div>
                <div className='row'>

                    <div className='table-head row'>
                        <div className='col-md-4'>
                            <div className='totle-use'>
                                <h4>Total Users: <b>{DEFENCEDATA.length}</b></h4>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="mb-3">
                                <input
                                    type="text"
                                    placeholder="Search by name, email, or phone"
                                    className="form-control"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-md-2'><Link to="/dashboard/add-venders" className="btn btn-success mb-3"><IoIosAddCircleOutline />
                            Add User</Link></div>
                    </div></div>


                <div className="row">
                    <div className="col-md-12">
                        <table className="table ">
                            <thead className="table-light">
                                <tr>
                                    <th>id</th>
                                    <th>venderImage</th>
                                    <th>title</th>
                                    <th>instructor</th>
                                    <th>duration</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {DEFENCEDATA.length > 0 ? DEFENCEDATA.map((user) => (
                                    <tr key={user.id}>
                                        <td>{user.id}</td>
                                        <td>
                                            <img
                                                src={user.venderImage}
                                                alt={user.title}
                                                style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                                            />
                                        </td>
                                        <td>{user.title}</td>
                                        <td>{user.instructor}</td>
                                        <td>{user.duration}</td>
                                        <td>
                                            <Link to={`/dashboard/edit-vender/${user.id}`} className='btn btn-primary me-2'><CiEdit />
                                                Edit</Link>
                                            <button
                                                type="button"
                                                className='btn btn-danger'
                                                // Pass the user ID here
                                                onClick={() => deleteHandleUser(user.id)}>
                                                <MdDeleteOutline />
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td colSpan="6" className="text-center">No users found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default TotalVenders;
