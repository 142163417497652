import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';

import axios from 'axios';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import "./dashboard.css";

function Dashboard1() {
   

    return (
        <div className="wrapper">
            <Sidebar />
            <div className="main">
                <Header />
                <Outlet/>
                <Footer />
            </div>
        </div>
    );
}

export default Dashboard1;
