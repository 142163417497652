import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { FaUsers } from "react-icons/fa";
import DEFENCEDATA from '../.././dummyData/DAdb.json'
import SPORTSHOPDATA from "../.././dummyData/shopdb.json";
import ACADMICDATA from "../.././dummyData/acadmic.json";

function DashboardHome1() {
    const [users, setUsers] = useState([]);
    const [VenderUsers, setVenderUsers] =useState([]);
    const [shopusers, setshopusers]=useState([]);

    useEffect(() => {
        fetchUsers();
        fetchTotalVenders();
        fetchTotalSportShop();
    }, []);

    const fetchUsers = () => {
        axios.get('http://localhost:3004/users')
            .then((response) => {
                setUsers(response.data);
            })
            .catch((error) => {
                console.error('Error fetching users:', error);
            });
    };
    const fetchTotalVenders = () => {
        axios.get('http://localhost:3005/VenderUsers')
            .then((response) => {
                setVenderUsers(response.data);
            })
            .catch((error) => {
                console.error('Error fetching users:', error);
            });
    };
    const fetchTotalSportShop = () => {
        axios.get('http://localhost:3006/shopusers')
            .then((response) => {
                setshopusers(response.data);
            })
            .catch((error) => {
                console.error('Error fetching users:', error);
            });
    };
  return (
    <main className="content px-3 py-2">
    <div className="container-fluid">
        <div className="title-bar1">
            <h4>User Dashboard</h4>
            <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item"><a href="#">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">Project Dashboard</li>
            </ol>
        </div>
        <div className="row">
            {/* <div className="col-md-4">
                <div className="card bg-gradient-start-1">
                    <div className="card-body">
                        <Link to="/usermanagment/users">
                            <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                                <div>
                                    <p className="fw-medium text-primary-light mb-1">Total Users</p>
                                    <h6 className="mb-0"><b>{ACADMICDATA.length}</b></h6>
                                </div>
                                <div className="icon-bar">
                                    <FaUsers />
                                </div>
                            </div>
                            <p className="fw-medium text-sm text-primary-light mt-12 mb-0 d-flex align-items-center gap-2">
                                <span className="d-inline-flex align-items-center gap-1 text-success-main">
                                    <iconify-icon icon="bxs:up-arrow" className="text-xs"></iconify-icon> +5000
                                </span>
                                Last 30 days users
                            </p>
                        </Link>
                    </div>
                </div>
            </div> */}
            <div className="col-md-4">
                <div className="card bg-gradient-start-1">
                    <div className="card-body">
                        <Link to="/deffenceAcademyUser/VenderUsers">
                            <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                                <div>
                                    <p className="fw-medium text-primary-light mb-1">Total Deffence Users</p>
                                    <h6 className="mb-0"><b>{DEFENCEDATA.length}</b></h6>
                                </div>
                                <div className="icon-bar">
                                    <FaUsers />
                                </div>
                            </div>
                            <p className="fw-medium text-sm text-primary-light mt-12 mb-0 d-flex align-items-center gap-2">
                                <span className="d-inline-flex align-items-center gap-1 text-success-main">
                                    <iconify-icon icon="bxs:up-arrow" className="text-xs"></iconify-icon> +5000
                                </span>
                                Last 30 days users
                            </p>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="card bg-gradient-start-1">
                    <div className="card-body">
                        <Link to="/usermanagment/users">
                            <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                                <div>
                                    <p className="fw-medium text-primary-light mb-1">Total SportShop Users</p>
                                    <h6 className="mb-0"><b>{SPORTSHOPDATA.length}</b></h6>
                                </div>
                                <div className="icon-bar">
                                    <FaUsers />
                                </div>
                            </div>
                            <p className="fw-medium text-sm text-primary-light mt-12 mb-0 d-flex align-items-center gap-2">
                                <span className="d-inline-flex align-items-center gap-1 text-success-main">
                                    <iconify-icon icon="bxs:up-arrow" className="text-xs"></iconify-icon> +5000
                                </span>
                                Last 30 days users
                            </p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
  )
}

export default DashboardHome1
