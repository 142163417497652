
import React, {useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import VendorCard from '../components/VendorCard';
import categories from '../dummyData/acadmic.json'



export default function VenderListingAcademy() {
    const { sportId } = useParams();

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    const category = categories.find((cat) => cat.id == sportId);
    
    if (!category) {
        return <p>Category not found!</p>; // Handle invalid IDs
      }

    return (
        <div className='container'>
           
            {category.subCategories.map((card, index) => (
               
               <Link 
               to={`/cat/${sportId}/${card.id}`} // Navigate to the specific subcategory page
               key={index} 
               style={{ textDecoration: 'none', color: 'inherit' }} // Optional: Styling for the link
             >
               <VendorCard sportId={sportId} data={card} />
             </Link>
            ))}
          
        </div>
    );
}


