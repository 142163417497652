import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from '../Sidebar';
import Header from '../Header';
import Footer from '../Footer';
import axios from 'axios';

const EditSportShop = () => {
    const [catTitle, setCatTitle] = useState('');
    const [catProfileImage, setcatProfileImage] = useState('');
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`http://localhost:3006/shopusers/${id}`)
            .then((response) => {
                setCatTitle(response.data.catTitle);
                setcatProfileImage(response.data.profileImage);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const updateHandleUser = (e) => {
        e.preventDefault();

        const updatedUser = {
            catTitle,
            catProfileImage,
        };

        axios.put(`http://localhost:3006/shopusers/${id}`, updatedUser)
            .then(() => {

                navigate('/SportsShopManagement/shopusers');
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <main className="content px-3 py-2">
        <div className="container-fluid">
            <div className="title-bar">
                <h4>Edit User {catTitle} </h4>
            </div>
            <div className='row'>
                <div className="col-md-12">
                    <form onSubmit={updateHandleUser}>
                        <div className="mb-3">
                            <label>Name:</label>
                            <input
                                type="text"
                                name="name"
                                value={catTitle}
                                onChange={(e) => setCatTitle(e.target.value)}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label>Profile Image URL:</label>
                            <input
                                type="text"
                                name="profileImage"
                                value={catProfileImage}
                                onChange={(e) => setcatProfileImage(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <button type="submit" className="btn btn-success">Update vender</button>
                    </form>
                </div>
            </div>
        </div>
    </main>

    );
};

export default EditSportShop;
