import React from 'react'
// import Navbar from '../components/Navbar'
import HomeBanner from '../components/HomeBanner'
import CategoryMenu from '../components/CategoryMenu'
import WeatherApp from '../components/WeatherApp'
import ServiceCard from '../components/ServiceCard';
import Trainers from "../components/SportsShop"
import RatedTrainers from '../components/RatedTrainers'
import ContactForm from '../components/ContactForm'
import Service2 from '../components/Service2'
import StayHealthy from '../components/StayHealthy'
import WhyJoinUs from '../components/WhyJoinUs'
import DeffenceTrainingCe from '../components/DeffenceTrainingCe'
import SportsEquipmentCat from '../components/SportsEquipmentCat';
import TrainerRegistration from '../components/TrainerForm';
import TestimonialSection from '../components/Testimonial';


function HomePage() {
  return (
    <div>
      <HomeBanner />  
      <CategoryMenu />
      <SportsEquipmentCat/>
      <DeffenceTrainingCe/>
      <Trainers />
      <StayHealthy/>
      <WhyJoinUs/>
      <Service2 />
      <ServiceCard />
      <RatedTrainers />
      <TrainerRegistration/>
      <WeatherApp />
      <ContactForm />
      <TestimonialSection/>
    </div>
  )
}

export default HomePage
