import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { IoIosAddCircleOutline } from "react-icons/io";
import axios from "axios";
import ACADMICDATA from "../../../dummyData/acadmic.json";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
// import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 

const UserTable1 = () => {
  const [categoryanduser, setCategoryAndUser] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchUser();
  }, []);


  $(document).ready(function () {
    $('#example').DataTable();
});


  const fetchUser = () => {
    axios
      .get("http://localhost:3008/categoryanduser")
      .then((response) => {
        setCategoryAndUser(response.data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };

  const deleteHandleUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`http://localhost:3008/categoryanduser/${id}`)
          .then(() => {
            fetchUser();
            Swal.fire("Deleted!", "User has been deleted.", "success");
          })
          .catch((error) => {
            console.error("Error deleting user:", error);
            Swal.fire(
              "Error!",
              "There was an error deleting the user.",
              "error"
            );
          });
      }
    });
  };

  const filteredUsers = categoryanduser.filter((user) => {
    const categoryName = user.categoryName || "";
    const title = user.title || "";
    const phone = user.phone || "";
    const address = user.address || "";
    const isPaid = user.isPaid || "";
    const description = user.description || "";

    return (
      categoryName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      phone.includes(searchQuery) ||
      address.toLowerCase().includes(searchQuery.toLowerCase()) ||
      isPaid.toLowerCase().includes(searchQuery.toLowerCase()) ||
      description.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }
);

  return (
    <main className="content px-3 py-2">
      <div className="container-fluid row d-flex align-items-center">
        <div className="title-bar1 col-md-4">
          <h4>User Management</h4>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Users List
            </li>
          </ol>
        </div>
        <div className="col-md-6">
              <div className="totle-use">
                <h4>
                  Total Users: <b>{ACADMICDATA.length}</b>
                </h4>
              </div>
            </div>
            <div className="col-md-2">
              <Link to="/dashboard/add-user" className="btn btn-success mb-3">
                <IoIosAddCircleOutline /> Add User
              </Link>
            </div>

        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <table id="example" className="display table table-bordered">
                <thead className="table-light">
                  <tr>
                    <th>ID</th>
                    <th>Profile Image</th>
                    <th>Category Name</th>
                    <th>Phone</th>
                    <th>Paid/Free</th>
                    <th>Address</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {ACADMICDATA.length > 0 ? (
                    ACADMICDATA.map((user) => (
                      <tr key={user.id}>
                        <td>{user.id}</td>
                        <td>
                          <img
                            src={`http://localhost:3000/${user.subCategories[0]?.image}`}
                            alt={user.categoryName}
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                            }}
                          />
                        </td>
                        <td>{user.label || "N/A"}</td>
                        <td>{user.subCategories[0]?.phone || "N/A"}</td>
                        <td>{"Paid"}</td>
                        <td>
                          {user.subCategories[0]?.address?.slice(0, 20) || "N/A"}
                        </td>
                        <td>{user.subCategories[0]?.name || "N/A"}</td>
                        <td>{user.subCategories[0]?.description || "N/A"}</td>
                        <td>
                          <Link
                            to={`/dashboard/edit-user/${user.id}`}
                            className="btn btn-primary me-2"
                          >
                            <CiEdit /> Edit
                          </Link>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => deleteHandleUser(user.id)}
                          >
                            <MdDeleteOutline /> Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center">
                        No users found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default UserTable1;
