import React from 'react';

function UserList() {
    return (
        <div className="container">
            <h2>Registered Users</h2>


        </div >
    );
}

export default UserList;
