import React, { useEffect } from 'react';
import myphoto7 from '../assets/khiladilogo.png';
import { Link } from 'react-router-dom';

const serviceData = [
  {
    id: 1,
    imgUrl: [myphoto7],
    title: 'First Card',
    description: 'Hello from Narendra',
  },
  {
    id: 2,
    imgUrl: [myphoto7],
    title: 'Second Card',
    description: 'Hello from Narendra',
  },
  {
    id: 3,
    imgUrl: [myphoto7],
    title: 'Third Card',
    description: 'Hello from Narendra',
  },
  {
    id: 4,
    imgUrl: [myphoto7],
    title: 'Fourth Card',
    description: 'Hello from Narendra',
  },
  {
    id: 5,
    imgUrl: [myphoto7],
    title: 'Fifth Card',
    description: 'Hello from Narendra',
  },
  {
    id: 6,
    imgUrl: [myphoto7],
    title: 'Sixth Card',
    description: 'Hello from Narendra',
  },
  {
    id: 7,
    imgUrl: [myphoto7],
    title: 'Seventh Card',
    description: 'Hello from Narendra',
  },
  {
    id: 8,
    imgUrl: [myphoto7],
    title: 'Eighth Card',
    description: 'Hello from Narendra',
  },
];

function ServiceCard() {
 
  return (
    <>
      <style>
        {`
          .more-services-link {
            text-decoration: none;
            padding: 10px 20px;
            border-radius: 5px;
            color: white;
            background-color: #2991c8;
            transition: background-color 0.3s ease, color 0.3s ease;
            float: right;
          }

          .more-services-link:hover {
            background-color:  #E86F2E;
            color: white;
            text-decoration: none;
          }

          .card-img-top {
            object-fit: contain;
            height: 200px;
          }

          .container {
            margin-top: 20px;
          }

          .row-gap-4 {
            row-gap: 1.5rem;
          }

          .btn-primary {
            background-color: #007bff;
            border-color: #007bff;
          }

          .btn-primary:hover {
            background-color: green;
            border-color: #004085;
          }

          .checkservice {
            align-items: center !important;
            background-color: #2991c8;
            color:white;
            padding:8px; 
          }
            .checkservice:hover{
            background-color: #E86F2E;
            color:white;
          }


        `}
      </style>

      <div className="container mt-5">
  {/* Title and Link Row */}
  <div className="row justify-content-center mt-5 mb-4">
    <div className="col-12 text-center">
      <h2>Sports Academy</h2>
    </div>
  </div>

  {/* Service Cards */}
  <div className="row row-gap-4">
    {serviceData.length > 0 &&
      serviceData.map((item) => (
        <div className="col-md-3 " key={item.id} >
          <div className="card px-0 pt-0">
            <img src={item.imgUrl} className="card-img-top" alt="" />
            <div className="card-body text-center">
              <h5 className="card-title">{item.title}</h5>
              <p className="card-text">{item.description}</p>
              <Link to="vaibhav_enterprises_ghaziabad_1234" className="rounded checkservice">
                Check Service
              </Link>
            </div>
          </div>
        </div>
      ))}
  </div>

  {/* More Services Link */}
  <div className="mt-4">
    <Link to="/" className="more-services-link">
      More Services
    </Link>
  </div>
</div>

    </>
  );
}

export default ServiceCard;
