import React, { useState } from 'react';
import LeftImage from "../assets/hero_left.jpg";
import rightTopImage from "../assets/hero_right_top.avif";
import rightBottomImage from "../assets/hero_right_bottom.jpg";
import HeroPlayLogo from "../assets/hero_playo_logo.png";
// import SearchCityComponent from './SearchCityComponent';
import DUMMYCITIES from './dummydata.json'
import { Form } from 'react-bootstrap';
import cardImage from "../assets/card_img.png";

function HomeBanner() {
    const [showTrainerModal, setShowTrainerModal] = useState(false);
    const [showJobModal, setShowJobModal] = useState(false);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedDist, setSelectedDist] = useState("");
    const [selectedState, setSelectedState] = useState('Select State');
    const [districts, setDistricts] = useState([]);

    const showTrainerModalHandler = () => {
        setShowTrainerModal(true);
    };

    console.log(selectedDist)

    const hideTrainerModal = () => {
        setShowTrainerModal(false);
    };

    const showJobModalHandler = () => {
        setShowJobModal(true);
    };

    const hideJobModal = () => {
        setShowJobModal(false);
    };

    const handleRegisterClick = () => {
        setIsFormVisible(true);
        document.body.style.overflow = "hidden"; // Prevent background scrolling
    };

    const handleCloseForm = () => {
        setIsFormVisible(false);
        document.body.style.overflow = "auto"; // Restore scrolling
    };

    // Dynamic category options
    const searchCategory = [
        { value: '', label: 'Category' },
        { value: 'sports_trainer', label: 'Sports Academies' },
        { value: 'Sports School', label: 'Sports School' },
        { value: 'Sports College', label: 'Sports College' },
        { value: 'Sports Education', label: 'Sports Education' },
        { value: 'Equipments', label: 'Equipments' },
        { value: 'Sports NGO', label: 'Sports NGO' },
        { value: 'Federations', label: 'Federations' },
        { value: 'Sports Events', label: 'Sports Events' },
        { value: 'Sports Services', label: 'Sports Services' },
        { value: 'Infrastructure', label: 'Infrastructure' },
        { value: 'Sports Coach', label: 'Sports Coach' },
        { value: 'Fitness Trainer', label: 'Fitness Trainer' },
        { value: 'Personal Trainer', label: 'Personal Trainer' },
        { value: 'Sports Physical Coach', label: 'Sports Physical Coach' },
        { value: 'Sports nutrition', label: 'Sports Nutrition' },
        { value: 'Sports Jobs', label: 'Sports Jobs' },
        { value: 'Sports Books', label: 'Sports Books' },
        { value: 'Sports Wear & Shoes', label: 'Sports Wear & Shoes' },
        { value: 'Sports Shop', label: 'Sports Shop' },
        { value: 'OLD Equipments For Sale', label: 'OLD Equipments For Sale' },
        { value: 'Defence Training Center', label: 'Defence Training Center' },
    ].sort((a, b) => a.label.localeCompare(b.label));

    // Handle change in category selection
    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    // Handle state selection
    const handleStateChange = (e) => {
        const state = e.target.value;
        setSelectedState(state);
        setDistricts(DUMMYCITIES[state].sort() || []); // Set districts based on selected state
    };

    const handleDistrictChange = (e) => {
        setSelectedDist(e.target.value);
    };

    return (
        <div className='container-fluid px-5 '>
            <style>{styles.join('\n')}</style>

            <div className="row align-items-center flex-row-reverse">
                <div className="col-md-6 ">
                    <h2 className="mb-3 mt-5 text-center">Find Your Sport Solutions Near By</h2>
                    <p className="fs-20" style={{ lineHeight: "1.75rem" }}>
                        Seamlessly explore sports venues and play with sports enthusiasts just like you!
                    </p>
                    <div className="w-100 mx-auto mt-5">
                        <div className="searchSection row row-gap-3">
                            {/* State Selector */}
                            <div className="col-12 searchSection1 col-md-6 col-lg-3">
                                <div>
                                    <Form.Select id="state" value={selectedState} onChange={handleStateChange}>
                                        <option value="">Select a state</option>
                                        {Object.keys(DUMMYCITIES).map((state) => (
                                            <option key={state} value={state}>
                                                {state}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </div>

                            {/* District Selector */}
                            <div className="col-12 searchSection1 col-md-6 col-lg-3">
                                {selectedState && (
                                    <Form.Select id="district" onChange={handleDistrictChange}>
                                        <option value="">Select a district</option>
                                        {districts.map((district) => (
                                            <option key={district} value={district}>
                                                {district}
                                            </option>
                                        ))}
                                    </Form.Select>
                                )}
                            </div>

                            {/* Category Selector */}
                            <div className="col-12 searchSection1 col-md-6 col-lg-3">
                                <Form.Select
                                    value={selectedCategory}
                                    onChange={handleCategoryChange}
                                    className="form-select py-2 custom-select"
                                >
                                    {searchCategory.map((category, index) => (
                                        <option key={index} value={category.value}>
                                            {category.label}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>

                            {/* Search Button */}
                            <div className="col-12 searchSection1 col-md-6 col-lg-3 btn11 btn123">
                                <button
                                    name="Search"
                                    className="py-2 w-100"
                                    style={{
                                        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                                        borderRadius: "7px",
                                        backgroundColor: "#2991c8",
                                        color: "white",
                                    }}
                                >
                                    Search
                                </button>
                            </div>
                        </div>

                        {/* Buttons Section */}
                        <div className="w-100 mt-5 mx-auto">
                            <div className="row d-flex align-items-center">
                                {/* Certified Trainer Button */}
                                <div className="col-12 col-md-4">
                                    <div className="d-flex justify-content-center btn11">
                                        <button
                                            onClick={handleRegisterClick}
                                            className="btn btn-danger btn-jobs text-center cursor-pointer py-2 fs-18 rounded text-white fw-bold"
                                            style={{
                                                boxShadow: "rgba(0, 0, 0, 0.1) 4px 10px 12px",
                                                backgroundColor: "red",
                                                color: "white",
                                            }}
                                        >
                                            Become a Certified Trainer
                                        </button>
                                    </div>
                                </div>

                                {/* Searching Trainers Button */}
                                <div className="col-12 col-md-4">
                                    <div className="d-flex justify-content-center btn11">
                                        <button
                                            onClick={showTrainerModalHandler}
                                            className="text-center lookingfortrainers btn-jobs cursor-pointer py-2 fs-18 rounded text-white fw-bold"
                                            style={{
                                                boxShadow: "rgba(0, 0, 0, 0.1) 4px 10px 12px",
                                                backgroundColor: "#2991c8",
                                                color: "white",
                                            }}
                                        >
                                            Searching Trainers?
                                        </button>
                                    </div>
                                </div>

                                {/* Looking for Jobs Button */}
                                <div className="col-12 col-md-4">
                                    <div className="d-flex justify-content-center btn11">
                                        <button
                                            onClick={showJobModalHandler}
                                            className="btn btn-danger btn-jobs text-center cursor-pointer py-2 fs-18 rounded text-white fw-bold"
                                            style={{
                                                boxShadow: "rgba(0, 0, 0, 0.1) 4px 10px 12px",
                                                backgroundColor: "red",
                                                color: "white",
                                            }}
                                        >
                                            Looking for Jobs?
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 mt-4">
                    <div className="banner-img-container position-relative w-100 d-flex" style={{ borderRadius: "10px", overflow: "hidden" }}>
                        <img className="w-50" src={LeftImage} alt="" />
                        <div className="w-50">
                            <div className="w-100">
                                <img className="w-100" src={rightTopImage} alt="" />
                            </div>
                            <div className="w-100">
                                <img width="100%" src={rightBottomImage} alt="" />
                            </div>
                        </div>

                        {/* Center the HeroPlayLogo */}
                        <div className="position-absolute hero-logo-container">
                            <img src={HeroPlayLogo} alt="" className="hero-logo" />
                        </div>
                    </div>
                </div>
            </div>

            {isFormVisible && (
                <>
                    <div className="checkout-modal-overlay" onClick={handleCloseForm}></div>
                    <div className="checkout-form-wrapper">
                        <form className="checkout-form">

                            <div className="row">
                                <div className="col-6 col-md-6">
                                    <h3 className="checkout-title">Billing Address</h3>
                                    <div className="checkout-inputBox">
                                        <span>Full Name:</span>
                                        <input type="text" placeholder="Enter Full Name " />
                                    </div>
                                    <div className="checkout-inputBox">
                                        <span>Email:</span>
                                        <input type="email" placeholder="Enter email Id" />
                                    </div>
                                    <div className="checkout-inputBox">
                                        <span>Address:</span>
                                        <input type="text" placeholder="Enter address" />
                                    </div>
                                    <div className="checkout-inputBox">
                                        <span>City:</span>
                                        <input type="text" placeholder="Enter City" />
                                    </div>
                                    <div className="checkout-flex">
                                        <div className="checkout-inputBox">
                                            <span>State:</span>
                                            <input type="text" placeholder="Enter State" />
                                        </div>
                                        <div className="checkout-inputBox">
                                            <span>Zip Code:</span>
                                            <input type="text" placeholder="PinCode" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6 col-md-6">
                                    <h3 className="checkout-title">Payment</h3>
                                    <div className="checkout-inputBox">
                                        <span>Cards Accepted:</span>
                                        <img className="imgCard" src={cardImage} alt="Accepted Cards" />
                                    </div>
                                    <div className="checkout-inputBox">
                                        <span>Name on Card:</span>
                                        <input type="text" placeholder="Mr. Monty Thakur" />
                                    </div>
                                    <div className="checkout-inputBox">
                                        <span>Credit Card Number:</span>
                                        <input type="number" placeholder="1111-2222-3333-4444" />
                                    </div>
                                    <div className="checkout-inputBox">
                                        <span>Exp Month:</span>
                                        <input type="text" placeholder="January" />
                                    </div>
                                    <div className="checkout-flex">
                                        <div className="checkout-inputBox">
                                            <span>Exp Year:</span>
                                            <input type="number" placeholder="2022" />
                                        </div>
                                        <div className="checkout-inputBox">
                                            <span>CVV:</span>
                                            <input type="text" placeholder="1234" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-actions">
                                <input
                                    type="submit"
                                    value="Proceed to Checkout"
                                    className="checkout-submit-btn"
                                />
                                <button
                                    type="button"
                                    className="checkout-close-btn"
                                    onClick={handleCloseForm}
                                >
                                    Close
                                </button>
                            </div>
                        </form>
                    </div>
                </>
            )}

            {showTrainerModal && (
                <div className="modal fade show d-block" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <form action="">
                                    <div className="form-group mb-2">
                                        <label htmlFor="" className='form-label'>City</label>
                                        <select type="text" className="form-control">
                                            <option value="">Select City</option>
                                            <option value="">Aligarh</option>
                                            <option value="">Noida</option>
                                        </select>
                                    </div>
                                    <div className="form-group mb-2">
                                        <label htmlFor="" className='form-label'>Coach/Trainer</label>
                                        <select type="text" className="form-control">
                                            <option value="">Select Coach</option>
                                            <option value="">Narendra</option>
                                            <option value="">Monty</option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button className="noselect me-4 close-btn" onClick={hideTrainerModal}><span className="text">Close</span><span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path></svg></span></button>
                                <button type="button" className="rounded bannerpop">
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Job Modal */}
            {showJobModal && (
                <div className="modal fade show d-block" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body text-center">
                                <h5>Coming Soon</h5>
                            </div>
                            <div className="modal-footer">
                                <button className="noselect me-4 close-btn" onClick={hideJobModal}><span className="text">Close</span><span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path></svg></span></button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

// Define your CSS styles in an array
const styles = [
    `.btn123 button{ padding:25px; border:1px solid #2991c8}`,

    // Existing styles...
    `.hero-logo-container { 
            top: 50%; 
            left: 50%; 
            transform: translate(-50%, -50%); 
        }`,
    `.hero-logo { 
            max-width: 100px; 
        }`,
    `@media (max-width: 768px) { 
            .hero-logo { 
                max-width: 70px; /* Reduce size for smaller screens */ 
            } 
        }`,
    `@media (max-width: 576px) { 
            .hero-logo { 
                max-width: 50px; /* Further reduce size for extra small screens */ 
            } 
        }`,


    `.btn11 button:hover { background-color: #2179a4 !important; color: white; }`,
    `.btn-jobs { --color: #00A97F; --color2: rgb(10, 25, 30); background-color: transparent; border-radius: 6px; border: .3px solid var(--color); transition: .5s; position: relative; overflow: hidden; cursor: pointer; z-index: 1; font-weight: 300; font-size: 15px; font-family: 'Roboto', 'Segoe UI', sans-serif; text-transform: uppercase; color: var(--color);}`,
    `.btn-jobs::after, .btn-jobs::before { content: ''; display: block; height: 100%; width: 100%; transform: skew(90deg) translate(-50%, -50%); position: absolute; inset: 50%; left: 25%; z-index: -1; transition: .5s ease-out; background-color: var(--color);}`,
    `.btn-jobs::before { top: -50%; left: -25%; transform: skew(90deg) rotate(180deg) translate(-50%, -50%);}`,
    `.btn-jobs:hover::before { transform: skew(45deg) rotate(180deg) translate(-50%, -50%);}`,
    `.btn-jobs:hover::after { transform: skew(45deg) translate(-50%, -50%);}`,
    `.btn-jobs:hover { color: var(--color2);}`,
    `.btn-jobs:active { filter: brightness(.7); transform: scale(.98);}`,
    `.bannerpop { background-color: #2991c8 !important; color: white; }`,
    `.bannerpop:hover { background-color: #2179a4 !important; color: white; }`,
    `input[type="text"]:hover { border-color: blue; }`,
    `.custom-select { max-height: 200px !important; overflow-y: auto !important; }`,  // Custom height for the dropdown
    `@media (max-width: 768px) { .btn11 { flex-direction: column; align-items: center; } .btn11 button { width: 100%; margin-bottom: 10px; }}`,
    `@media (max-width: 1447px){.btn-jobs { --color: #00A97F; --color2: rgb(10, 25, 30); background-color: transparent; border-radius: 6px; border: .3px solid var(--color); transition: .5s; position: relative; overflow: hidden; cursor: pointer; z-index: 1; font-weight: 300; font-size: 12px; font-family: 'Roboto', 'Segoe UI', sans-serif; text-transform: uppercase; color: var(--color);}`,
    `@media (max-width: 1447px){.searchSection1 {  font-weight: 300; font-size: 12px; font-family: 'Roboto', 'Segoe UI', sans-serif; text-transform: uppercase; color: var(--color);}`,
    `.close-btn { width: 150px; height: 50px; cursor: pointer; display: flex; align-items: center; background: #e62222; border: none; border-radius: 5px; box-shadow: 1px 1px 3px rgba(0,0,0,0.15);}`,
    `.close-btn, .close-btn span { transition: 200ms;}`,
    `.close-btn .text { transform: translateX(35px); color: white; font-weight: bold;}`,
    `.close-btn .icon { position: absolute; border-left: 1px solid #c41b1b; transform: translateX(110px); height: 40px; width: 40px; display: flex; align-items: center; justify-content: center;}`,
    `.close-btn:hover { background: #ff3636;}`,
    `.close-btn:hover .text { color: transparent;}`,
    `.close-btn:hover .icon { width: 150px; border-left: none; transform: translateX(0);}`,
    `.close-btn:focus { outline: none;}`,
    `.close-btn:active .icon svg { transform: scale(0.8);}`,
    `.custom-select { max-height: 200px !important; overflow-y: auto !important; }`,
    `option{height:100px !important; overflow-y:scroll !important;}`,
];

export default HomeBanner;
