import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from '../Sidebar';
import Header from '../Header';
import Footer from '../Footer';
import axios from 'axios';

const EditDeffenceVender = () => {
    const [title, setTitle] = useState('');
    const [duration, setduration] = useState('');
    const [instructor, setinstructor] = useState('');
    const [profileImage, setProfileImage] = useState('');
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`http://localhost:3004/users/${id}`)
            .then((response) => {
                setTitle(response.data.title);
                setduration(response.data.duration);
                setinstructor(response.data.instructor);
                setProfileImage(response.data.profileImage);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const updateHandleUser = (e) => {
        e.preventDefault();

        const updatedUser = {
            title,
            duration,
            instructor,
            profileImage,
        };

        axios.put(`http://localhost:3004/users/${id}`, updatedUser)
            .then(() => {

                navigate('/deffenceAcademyUser/users');
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <main className="content px-3 py-2">
        <div className="container-fluid">
            <div className="title-bar">
                <h4>Edit User {title} </h4>
            </div>
            <div className='row'>
                <div className="col-md-12">
                    <form onSubmit={updateHandleUser}>
                        <div className="mb-3">
                            <label>Name:</label>
                            <input
                                type="text"
                                name="name"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label>Phone:</label>
                            <input
                                type="text"
                                name="phone"
                                value={duration}
                                onChange={(e) => setduration(e.target.value)}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label>Email:</label>
                            <input
                                type="email"
                                name="email"
                                value={instructor}
                                onChange={(e) => setinstructor(e.target.value)}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label>Profile Image URL:</label>
                            <input
                                type="text"
                                name="profileImage"
                                value={profileImage}
                                onChange={(e) => setProfileImage(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <button type="submit" className="btn btn-success">Update vender</button>
                    </form>
                </div>
            </div>
        </div>
    </main>

    );
};

export default EditDeffenceVender;
