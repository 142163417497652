import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const EditUser1 = () => {
  const { id } = useParams(); // Get user ID from the URL
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Fetch user details by ID
    axios
      .get(`http://localhost:3004/categoryanduser/${id}`)
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user:", error);
      });
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Make PUT request to update user data
    axios
      .put(`http://localhost:3004/categoryanduser/${id}`, user)
      .then((response) => {
        console.log("User updated successfully:", response.data);
        navigate("/usermanagment/categoryanduser");
      })
      .catch((error) => {
        console.error("Error updating user:", error);
      });
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <main className="content px-3 py-2">
      <div className="container-fluid">
        <div className="title-bar">
          <h4>Edit User</h4>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            {/* Left Column */}
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="username" className="form-label">
                  Username
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  value={user.username}
                  onChange={(e) => setUser({ ...user, username: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  value={user.phone}
                  onChange={(e) => setUser({ ...user, phone: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={user.email}
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                />
              </div>
            </div>

            {/* Right Column */}
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="address" className="form-label">
                  Address
                </label>
                <textarea
                  className="form-control"
                  id="address"
                  rows="3"
                  value={user.address}
                  onChange={(e) => setUser({ ...user, address: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="isPaid" className="form-label">
                  Paid or Free
                </label>
                <select
                  className="form-control"
                  id="isPaid"
                  value={user.isPaid}
                  onChange={(e) => setUser({ ...user, isPaid: e.target.value })}
                >
                  <option value="Paid">Paid</option>
                  <option value="Free">Free</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  value={user.title}
                  onChange={(e) => setUser({ ...user, title: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Description
                </label>
                <textarea
                  className="form-control"
                  id="description"
                  rows="3"
                  value={user.description}
                  onChange={(e) => setUser({ ...user, description: e.target.value })}
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Update User
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  );
};

export default EditUser1;
