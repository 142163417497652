import React, { useState, useEffect } from 'react';
import bgImage from '../assets/weather-bg.avif'; // Replace this with the correct path to your image

function WeatherApp() {
  const [city, setCity] = useState('delhi');
  const [weather, setWeather] = useState({});
  const [error, setError] = useState('');

  const apiKey = 'ceed6eb960f0f74a4601e8ae02bc75fc'; // Replace with your OpenWeatherMap API key

  const fetchWeather = async () => {
    try {
      const response = await fetch(
        `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${apiKey}&units=metric`
      );
      const data = await response.json();
      if (response.ok) {
        setWeather(data);
        setError('');
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError('Error fetching data');
    }
  };

  // Automatically call the API when the user enters at least 3 characters
  useEffect(() => {
    if (city.length >= 3) {
      fetchWeather();
    } else {
      setWeather({});
      setError(''); // Clear error when fewer than 3 characters
    }
  }, [city]); // Only run this when `city` changes

  return (
    <div style={styles.appContainer}>
      <div style={styles.backgroundBlur} />
      <div style={styles.weatherContainer}>
        <div style={styles.innerContainer}>
          <h1 className='text-center mb-3'>Weather App</h1>
          <div className="row justify-content-center align-items-center">
            <div className="col-md-6">
              <div className="row justify-content-center">
                <div className="col-12 col-md-10">
                  <div className="form-group">
                    <input
                      style={styles.inputArea}
                      type="text"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      placeholder="Enter city name"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {error && <p>{error}</p>}
              {weather.main && (
                <div className='text-center w-100 pt-3'>
                  <h2>{weather.name}, {weather.sys.country}</h2>
                  <p>Temperature: {weather.main.temp} °C</p>
                  <p>Weather: {weather.weather[0].description}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  appContainer: {
    position: 'relative', // Allows background to be separate from content
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '70vh',
    overflow: 'hidden', // Ensures content doesn’t spill outside the viewport
  },
  backgroundBlur: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url(${bgImage})`, // Apply the background image
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    filter: 'blur(4px)', // Apply the blur effect
    zIndex: -1, // Make sure the background is behind everything else
  },
  weatherContainer: {
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '20px',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)', // Shadow for container
    maxWidth: '800px',
    width: '100%',
    zIndex: 1, // Ensure it stays above the background
    textAlign: 'center',
  },
  innerContainer: {
    padding: '20px',
  },
  inputArea: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxShadow: 'inset 0px 1px 3px rgba(0, 0, 0, 0.1)', // Inner shadow for input
  },
};

export default WeatherApp;
