import React from 'react'
import './WhyJoinUs.css'
import WJUBanner from '../assets/WJUBanner.jpg'

export default function WhyJoinUs() {
  return (
    <div className='container'>
      <section className="section__container join__container">
      <h2 className="section__header text-center">Why Join Us ?</h2>
      <p className="section__subheader">
        Our diverse membership base creates a friendly and supportive
        atmosphere, where you can make friends and stay motivated.
      </p>
      <div className="join__image">
        <img src={WJUBanner} alt="Join" />
        <div className="join__grid">
          <div className="join__card">
            <span><i className="ri-user-star-fill"></i></span>
            <div className="join__card__content">
              <h4>Personal Trainer</h4>
              <p>Unlock your potential with our expert Personal Trainers.</p>
            </div>
          </div>
          <div className="join__card">
            <span><i className="ri-vidicon-fill"></i></span>
            <div className="join__card__content">
              <h4>Practice Sessions</h4>
              <p>Elevate your fitness with practice sessions.</p>
            </div>
          </div>
          <div className="join__card">
            <span><i className="ri-building-line"></i></span>
            <div className="join__card__content">
              <h4>Good Management</h4>
              <p>Supportive management, for your fitness success.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}
